import { Auth } from 'aws-amplify'

const awsconfig = {
  region: 'us-east-1',
  paymentHashUrl:
    'https://eq8wcmr7jb.execute-api.us-east-2.amazonaws.com/TestStage/getpaymenthash',
  userPoolId: 'us-east-1_JOiry6gUM',
  userPoolWebClientId: '1uopjpa195ujq8kcb60i82sp73',
  oauth: {
    domain: 'demo-pledgarden.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
    redirectSignOut: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
  },
  bucketUrl: 'https://pledge-garden-demo-upload-content.s3.amazonaws.com/',
  API: {
    endpoints: [
      {
        name: 'pg-api-v1',
        api_url: 'https://api.demo.extensia.cloud',
        endpoint: 'https://api.demo.extensia.cloud/v1',
        endpointv2: 'https://api.demo.extensia.cloud/v2',
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
          idtoken: `${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
  stripe_publish_key:
    'pk_test_51M2d2dLNf1nGis57atBC3XwAiTDUVdn4126eqx3zCwiC9YSpvRnF11P9CWlEvKxjsDS2kfZTDyxb8zAiLJoni7NF0041fU2RKT',
  stripe_connect_client_id: 'ca_MmBtMFaoKTFFI0QAbUskSMsiCrC5c1jJ',
  paypal_client_id:
    'AVI_E4jOxo37T1kcJRuR_1j4Cii8_TpuidZUk8KRd0xhV-DbGcTQ5a5chdbA5wpk10RW9Zpu020R0qCw',
  paypal_bncode: 'PledgeGarden_SP_PPCP',
  reCaptchaKey: '6Ld6NXkgAAAAAMLMVyXtaCAI3W28hgSpE8_fEP10',
  public_vapid_key:
    'BGHT_yJc1YI9fMpPVcDKITgRlOdlkQ1or2RZ7ObNKs5Rj9ph1KPGR6Klye31sTPBYk01GE5JZkqh-WcqKKuR-TQ',
  google_api_key: 'AIzaSyAu1COT1oscjAn-Jx8KyfAqwh2fNhrGXag',
  webapp_url: 'https://<org>.donate.demo.extensia.cloud',
}

export { awsconfig }
